import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Row, Col } from "react-bootstrap"
import ImageGallery from 'react-image-gallery';
import i18n from '../../helpers/i18n';

import "react-image-gallery/styles/scss/image-gallery.scss";
import '../../scss/pages/artwork.scss'

const urlHelper = require('../../helpers/url')

const Page = ({ data: { prismicArtwork } }) => {
  const { lang, data } = prismicArtwork
  i18n.setLanguage(lang)

  const artist = data.artist.document
  const technique = data.technique.document
  const contactUrl = urlHelper(lang, 'contact') + "?artwork=" + prismicArtwork.uid

  var images = [{
    original: data.primary_image.url,
    thumbnail: data.primary_image.url,
  }];

  data.additional_images.forEach(function(image) {
    images.push({
      original: image.image.url,
      thumbnail: image.image.url,
    });
  });

  var showThumbnails = images.length > 1;

  return (
    <Layout lang={lang}>
      <SEO title={data.title.text} />
      <Container>
        <div className="artwork-heading text-center">
          <h1 className="gls-title">{artist.data.name.text}</h1>
          <p className="gls-subtitle">{data.title.text}, {data.year}</p>
        </div>
        <div className="artwork-gallery">
          <ImageGallery items={images} showThumbnails={showThumbnails} thumbnailPosition="bottom" showNav={false} showFullscreenButton={false} showBullets={false} showPlayButton={false} />
        </div>
        <Row>
          <Col md="5">
            <div className="artwork-features">
              <strong>{i18n.artworkArtist}</strong>
              <span>{artist.data.name.text}</span>
              <strong>{i18n.artworkTitle}</strong>
              <span>{data.title.text}</span>
              <strong>{i18n.artworkTechnique}</strong>
              <span>{technique.data.name.text}</span>
              <strong>{i18n.artworkYear}</strong>
              <span>{data.year}</span>
              <strong>{i18n.artworkSize}</strong>
              <span>{data.size.text}</span>
              <strong>{i18n.artworkPrice}</strong>
              <span>{data.price}</span>
            </div>
          </Col>
          <Col md="7">
            <strong>{i18n.artworDescription}</strong>
            <div dangerouslySetInnerHTML={{ __html: data.description.html }} />
            <a className="gls-button artwork-contact" href={contactUrl}>{i18n.artworkInfo}</a>
          </Col>
        </Row>
      </Container>
      { artist.data.description.html && <div className="artwork-optional-content">
        <Container>
          <h3>{i18n.artworkAuthorNotes}</h3>
          <div dangerouslySetInnerHTML={{ __html: artist.data.description.html }} />
        </Container>
      </div> }
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ArtworkBySlug($uid: String!, $lang: String!) {
    prismicArtwork(uid: {eq: $uid}, lang: {eq: $lang}) {
      uid
      lang
      data {
        artist {
          uid
          document {
            ... on PrismicArtist {
              data {
                name {
                  text
                }
                description {
                  html
                }
              }
            }
          }
        }
        title {
          text
        }
        year
        primary_image {
          url
          alt
        }
        additional_images {
          image {
            url
          }
        }
        description {
          html
        }
        technique {
          uid
          document {
            ... on PrismicTechnique {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        size {
          text
        }
        price
      }
    }
  }
`
